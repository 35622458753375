import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const UpadloscPrawnik = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Upadłość Prawnik</h1>
          <h2>Introduction</h2>
          <p>
            The website design was quite complicated for a landing page. We had
            to include a lot of content for organic positioning. Thanks to this,
            it was able to position itself organically. The specific industry
            and poor market situation had a strong influence on the website's
            appearance. I wanted the website to contain as much information as
            possible for the client, but enough to make him want to get in
            touch. The flow of information was to make the customer aware of the
            quality and professionalism of the brand. After reading the whole
            thing, I wanted the client to be aware of what the company does and
            that he should choose it.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/flow.png" as="div" />
          <h2>Result</h2>
          <StaticImage
            src="./images/Screenshot 2022-10-28 at 12-55-29 Upadłość Konsumencka.png"
            as="div"
          />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default UpadloscPrawnik;

export function Head() {
  return <title>Pizzeria Luna</title>;
}